//Returns a string with the error, or an empty string if the password is valid
const validatePassword = (password: string, confirmPassword: string, email: string) => {
  if (password.length < 8) {
    return 'Password must be at least 8 characters long'
  }
  if (email.includes(password)) {
    return 'Password cannot be part of your email'
  }
  if (!/[0-9]/.test(password)) {
    return 'Password must include at least one number'
  }
  if (!/[a-zA-Z]/.test(password)) {
    return 'Password must include at least one letter'
  }
  if (!/[^0-9a-zA-Z]/.test(password)) {
    return 'Password must include at least one special character'
  }
  if (password !== confirmPassword) {
    return 'Passwords must match'
  }
  return ''
}

export default validatePassword