
import { useRouter } from 'vue-router'
import { computed, defineComponent, ref } from 'vue'
import validatePassword from '@/utils/validate-password'
import supabaseDb from '@/models/backends/backends-concrete/supabase/supabase-db'
import { showToast } from '@/components/general/Toasts.vue'
import { AuthHelperService, useServiceInjector } from '@/injection'

export default defineComponent({
  setup() {
    const router = useRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    const session = auth.getSessionFromUrl()

    const email = ref("")

    const token = ref("")

    const sessionPromise = session.then((t) => {
      token.value = t
      email.value = supabaseDb.client.auth.user()?.email ?? ""
    })

    const newPassword = ref("")
    const confirmNewPassword = ref("")

    const passwordError = computed(() => validatePassword(
      newPassword.value,
      confirmNewPassword.value,
      email.value
    ))

    const otherError = ref("")

    const showError = ref(false)

    const onSubmit = async () => {
      if (passwordError.value) {
        showError.value = true
        return
      }
      await sessionPromise
      try {
        auth.updatePassword(token.value, newPassword.value)
        showToast("Your password has been changed")
        router.push("/")
      }
      catch (e) {
        otherError.value = e.message
        showError.value = true
      }
    }

    return {
      onSubmit,
      passwordError,
      newPassword,
      confirmNewPassword,
      showError,
      otherError
    }
  },
})
