<template>
  <main class="content full-height col-centered">
    <teleport to="#title-bar">
      <h1>Four Circles</h1>
    </teleport>
    <div class="flex-spacer"></div>
    <h2>Create new password</h2>
    <section>
      <label class="field-name" for="password">New Password</label>
      <input type="password" id="password" v-model="newPassword"/>
    </section>
    <section>
      <label class="field-name" for="confirm-password">Confirm New Password</label>
      <input type="password" id="confirm-password" v-model="confirmNewPassword"/>
    </section>
    <p class="error" v-show="showError && ((passwordError && newPassword) || otherError)">
      <small>
        {{passwordError || otherError}}
      </small>
    </p>
    <div class="align-right">
      <button @click="onSubmit" :disabled="!newPassword">Submit</button>
    </div>
    <div class="flex-spacer"/>
  </main>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { computed, defineComponent, ref } from 'vue'
import validatePassword from '@/utils/validate-password'
import supabaseDb from '@/models/backends/backends-concrete/supabase/supabase-db'
import { showToast } from '@/components/general/Toasts.vue'
import { AuthHelperService, useServiceInjector } from '@/injection'

export default defineComponent({
  setup() {
    const router = useRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    const session = auth.getSessionFromUrl()

    const email = ref("")

    const token = ref("")

    const sessionPromise = session.then((t) => {
      token.value = t
      email.value = supabaseDb.client.auth.user()?.email ?? ""
    })

    const newPassword = ref("")
    const confirmNewPassword = ref("")

    const passwordError = computed(() => validatePassword(
      newPassword.value,
      confirmNewPassword.value,
      email.value
    ))

    const otherError = ref("")

    const showError = ref(false)

    const onSubmit = async () => {
      if (passwordError.value) {
        showError.value = true
        return
      }
      await sessionPromise
      try {
        auth.updatePassword(token.value, newPassword.value)
        showToast("Your password has been changed")
        router.push("/")
      }
      catch (e) {
        otherError.value = e.message
        showError.value = true
      }
    }

    return {
      onSubmit,
      passwordError,
      newPassword,
      confirmNewPassword,
      showError,
      otherError
    }
  },
})
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding-bottom: 20px;
}
.error {
  margin: 0 0 0 4px;
  color: var(--color-error);
}
.error * {
  opacity: 1;
}
</style>